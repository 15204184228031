var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "manager-container" },
    [
      [
        _c("div", { staticClass: "flex-between-row" }, [
          _c("h1", { staticClass: "div-main-view-title" }, [
            _vm._v(_vm._s(_vm.pageTitle)),
          ]),
        ]),
        _c(
          "el-row",
          { staticClass: "shadow-border-bottom", attrs: { type: "flex" } },
          [
            _c(
              "div",
              { staticClass: "button-block" },
              [
                _vm.hasSysAdminPermission
                  ? _c(
                      "el-select",
                      {
                        on: {
                          change: function ($event) {
                            return _vm.getJobTableData()
                          },
                        },
                        model: {
                          value: _vm.searchByStatus,
                          callback: function ($$v) {
                            _vm.searchByStatus = $$v
                          },
                          expression: "searchByStatus",
                        },
                      },
                      _vm._l(_vm.statusOpts, function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value },
                        })
                      }),
                      1
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "query-item search-bar" },
                  [
                    _c(
                      "el-input",
                      {
                        staticClass: "round-input",
                        attrs: {
                          placeholder: "Search By Barge, Vessel or Terminal",
                        },
                        nativeOn: {
                          keypress: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.getJobTableData()
                          },
                        },
                        model: {
                          value: _vm.jobSearchVal,
                          callback: function ($$v) {
                            _vm.jobSearchVal = $$v
                          },
                          expression: "jobSearchVal",
                        },
                      },
                      [
                        _c("el-button", {
                          attrs: {
                            slot: "append",
                            type: "primary",
                            icon: "el-icon-search",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.getJobTableData()
                            },
                          },
                          slot: "append",
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "table-block margin-top-0",
            style: { height: "calc(100% - 74px)" },
          },
          [
            _c("table-job", {
              attrs: {
                hasTableMenu: "",
                hasPagination: "",
                isLoading: _vm.isLoading,
                jobTableData: _vm.jobTableData,
                jobPagination: _vm.jobPagination,
              },
              on: {
                handleJobPageChange: _vm.handleJobPageChange,
                handleJobPageSizeChange: _vm.handleJobPageSizeChange,
                handleJobTableRefresh: _vm.handleJobTableRefresh,
                handlePushToMpa: _vm.handlePushToMpa,
              },
            }),
          ],
          1
        ),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }